<!-- 任务完成情况 -->
<template>
  <div
    class="result"
    v-if="
      resultData.status === 'TOJUDGE' ||
      resultData.status === 'FINISHED' ||
      resultData.status === 'REFUSED' ||
      resultData.status === 'FORBID'
    "
  >
    <div class="itemhead">
      <div class="circle"></div>
      <span>完成度</span>
    </div>
    <div class="body">
      <div class="degree" v-for="(item, index) in numSetting" :key="index">
        <div class="left">
          <p style="font-size: 0.9rem">{{ item.name }}</p>
          <p style="font-size: 1.1rem" :style="{ color: item.color }">
            {{
              resultData[item.fieId] && resultData[item.fieId] !== null
                ? resultData[item.fieId]
                : 0
            }}
          </p>
          <p style="font-size: 0.7rem; color: #999999">
            /{{
              salaryListVos[item.fieId] && salaryListVos[item.fieId] !== null
                ? salaryListVos[item.fieId]
                : 0
            }}
          </p>
        </div>
        <el-progress
          style="margin: 6px"
          type="circle"
          :percentage="
            comper(resultData[item.fieId], salaryListVos[item.fieId])
          "
          :color="item.color"
          :width="50"
          :format="
            formattext(resultData[item.fieId], salaryListVos[item.fieId])
          "
        ></el-progress>
      </div>
    </div>
    <div class="itemhead">
      <div class="circle"></div>
      <span>任务截图</span>
    </div>
    <div class="body">
      <el-image
        class="img"
        :src="item"
        v-for="(item, index) in imgList"
        :key="index"
        fit="fill"
        :preview-src-list="imgList"
      >
        <div slot="placeholder" class="image-slot">
          加载中<span class="dot">...</span>
        </div>
        <div
          slot="error"
          class="image-slot"
          style="
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #b1b1b165;
          "
        >
          <i class="el-icon-picture-outline" style="font-size: 2rem"></i>
        </div>
      </el-image>
    </div>
    <div class="itemhead">
      <div class="circle"></div>
      <span>任务文章链接</span>
    </div>
    <div class="body">
      <el-row style="display: flex; align-items: center">
        <a :href="resultData.annex" class="link" target="blank">
          {{ resultData.annex ? resultData.annex : "暂无文章链接" }}</a
        >
        <el-button
          class="copy"
          :data-clipboard-text="resultData.annex"
          size="mini"
          style="margin-left: 10px; background-color: #5874ef; color: white"
          round
          @click="copymsg"
          >复制</el-button
        >
      </el-row>
    </div>
  </div>
</template>

<script>
import { getresult } from "@/api/audit";
import Clipboard from "clipboard";
export default {
  props: {
    salaryListVos: {
      type: Array,
      default: () => {
        return [];
      },
    },
    missionReceiveId: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      numSetting: [
        {
          name: "互动数",
          finesh: 1000,
          color: "#5685EF",
          fieId: "totalNum",
        },
        {
          name: "点赞数",
          finesh: 1000,
          color: "#A362F4",
          fieId: "likeNum",
        },
        {
          name: "收藏数",
          finesh: 1000,
          color: "#62CEF4",
          fieId: "collectNum",
        },
        {
          name: "评论数",
          finesh: 1000,
          color: "#F49061",
          fieId: "commentNum",
        },
        {
          name: "转发数",
          finesh: 1000,
          color: "#F46180",
          fieId: "shareNum",
        },
      ],
      imgList: [],
      resultData: {},
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.getresult();
    });
  },
  watch: {
    missionReceiveId: {
      handler() {
        this.getresult();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    // 复制信息
    copymsg(msg) {
      let _this = this;
      // if (clipboard) {
      //   clipboard.destroy();
      // }
      let clipboard = new Clipboard(".copy");
      clipboard.on("success", function (e) {
        // _this.$message("复制成功");
        e.clearSelection();
      });
    },
    // 品牌方获取用户提交内容
    async getresult() {
      await getresult({
        missionReceiveId: this.missionReceiveId,
      }).then((res) => {
        if (res.data === null) {
          this.resultData = {};
          this.imgList = [];
        } else {
          this.resultData = res.data;
          this.imgList = res.data.picUrl.split(",");
        }
      });
    },
    // 计算进度
    comper(numErator, denoMinator) {
      let numerator = numErator ? numErator : 0;
      let denominator = denoMinator ? denoMinator : 0;
      if (denominator === 0) {
        return 100;
      } else {
        return numErator / denoMinator > 1 ? 100 : numErator / denoMinator;
      }
    },
    formattext(numErator, denoMinator) {
      let numerator = numErator ? parseInt(numErator) : 0;

      let denominator = denoMinator ? parseInt(denoMinator) : 0;
      if (denominator === 0) {
        return () => {
          return "100%";
        };
      } else {
        return () => {
          return (numerator / denominator) * 100 + "%";
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.result {
  height: calc(100%);
  .itemhead {
    display: flex;
    margin-bottom: 10px;
    font-size: 0.8rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    align-items: center;

    & span {
      display: block;
      margin-left: 10px;
    }

    .circle {
      position: relative;
      width: 0.9rem;
      height: 0.9rem;
      border-radius: 50%;
      background-color: #bccaf9;
    }

    .circle::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      border-radius: 50%;
      background-color: #587aef;
    }
  }

  .body {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;

    .degree {
      margin: 0 6px;
      //   flex-basis: calc(18%);
      width: 10rem;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      .left {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        box-sizing: border-box;
        padding: 6px 10px;
        flex: 1;
        line-height: 150%;

        .name {
          font-size: 0.9rem;
        }
      }
    }

    .img {
      width: 8rem;
      height: 8rem;
      margin: 0 10px;
      border: 1px solid #b1b1b165;
      border-radius: 10px;
    }

    .link {
      font-size: 0.8rem;
      padding: 8px 16px;
      display: inline-block;
      box-shadow: 0px 2px 4px 0px rgba(178, 186, 200, 0.3);
      border-radius: 4px;
      width: 20rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .link:hover {
      color: rgb(76, 76, 253);
      text-decoration: underline;
    }
  }
}
</style>